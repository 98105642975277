/* eslint-disable no-param-reassign */
import visit from 'unist-util-visit'

import { CALLRAIL_FORWARDING_TELEPHONE_NUMBER } from '../../src/config'

const formatTelephoneNumber = (inputTelephoneNumber) => {
  const telephoneNumber = inputTelephoneNumber.replace(/\s/g, '')
  const firstPart = telephoneNumber.substr(0, 3)
  const secondPart = telephoneNumber.substr(3, 4)
  const thirdPart = telephoneNumber.substr(7, 4)
  return `${firstPart} ${secondPart} ${thirdPart}`
}

const SHORTCODE_LABEL = 'phone number'

export const OUTPUT = `<a href="tel:${CALLRAIL_FORWARDING_TELEPHONE_NUMBER}" class="swap-target">${formatTelephoneNumber(
  CALLRAIL_FORWARDING_TELEPHONE_NUMBER
)}</a>`

const plugin = ({ markdownAST }) => {
  visit(markdownAST, ['linkReference'], (node) => {
    if (node.label === SHORTCODE_LABEL) {
      node.value = OUTPUT
      node.type = 'html'
      node.children = undefined
    }
  })
  return markdownAST
}

export default plugin
