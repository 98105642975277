import React from 'react'
import PropTypes from 'prop-types'
import { Wrapper, H } from '@farewill/ui'
import { SECTION_TYPES } from '../../constants'

const Content = ({ title, slug, sectionType, children }) => (
  <Wrapper separator id={slug}>
    {title && sectionType !== SECTION_TYPES.STEPS && (
      <H tag="h2" size="M">
        {title}
      </H>
    )}
    {children}
  </Wrapper>
)

Content.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  sectionType: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default Content
