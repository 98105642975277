import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { screenMax } from '@farewill/ui/helpers/responsive'
import { COLOR, GTR } from '@farewill/ui/tokens'
import { P, Wrapper } from '@farewill/ui'

import TelephoneNumber from 'components/TelephoneNumber'
import { CALLRAIL_FORWARDING_TELEPHONE_NUMBER } from 'config'
import useProductSupportStatus from 'lib/time/useProductSupportStatus'
import { formattedOpenHours } from 'lib/time/formatting'
import { LIVE_PRODUCTS, PRODUCTS_MAP } from 'lib/products/constants'

import LinkCta from './LinkCta'

const StyledTelephoneNumber = styled(TelephoneNumber)`
  ${screenMax.s`
    width: 100%;
  `}
`

const StyledP = styled(P)`
  &:first-child {
    margin-bottom: ${GTR.XS};
  }

  &:last-child {
    margin-top: ${GTR.XS};
  }
`

const StyledLink = styled(Link)`
  text-decoration: underline;
`

const PhoneCta = ({
  secondaryCta,
  primary,
  product,
  telephoneNumber,
  text,
  size,
}) => {
  const callbackLink = PRODUCTS_MAP[product]?.callbackLink
  return useProductSupportStatus(product) ? (
    <Wrapper>
      {!secondaryCta && (
        <StyledP strong color={COLOR.BLACK}>
          {text || 'Call us now'}
        </StyledP>
      )}
      <StyledTelephoneNumber
        highlighted
        highlightColor={primary ? COLOR.ACCENT.PRIMARY : COLOR.WHITE}
        telephoneNumber={
          telephoneNumber || CALLRAIL_FORWARDING_TELEPHONE_NUMBER
        }
        size={size}
      />
      {secondaryCta && callbackLink && (
        <StyledP color={COLOR.BLACK}>
          Or <StyledLink to={callbackLink}>request a callback</StyledLink>
        </StyledP>
      )}
    </Wrapper>
  ) : (
    <Wrapper>
      {!secondaryCta && callbackLink && (
        <StyledP strong color={COLOR.BLACK}>
          We’re here to help from {formattedOpenHours(product)}
        </StyledP>
      )}
      {callbackLink && (
        <LinkCta
          text="Request call back"
          primary={primary}
          link={callbackLink}
          showArrowIcon={false}
        />
      )}
      {secondaryCta && (
        <StyledP color={COLOR.BLACK}>
          We’re here to help from {formattedOpenHours(product)}
        </StyledP>
      )}
    </Wrapper>
  )
}

PhoneCta.propTypes = {
  product: PropTypes.oneOf(LIVE_PRODUCTS).isRequired,
  secondaryCta: PropTypes.bool,
  primary: PropTypes.bool,
  telephoneNumber: PropTypes.string,
  text: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
}

PhoneCta.defaultProps = {
  secondaryCta: false,
  primary: true,
  telephoneNumber: CALLRAIL_FORWARDING_TELEPHONE_NUMBER,
  text: null,
  size: 'small',
}

export default PhoneCta
