import React from 'react'
import { Image } from '@farewill/ui'

import { formatPathProps } from 'types/types'
import AspectRatioWrapper from 'components/AspectRatioWrapper'

import { getImageAspectRatio } from './helpers'
import { ContentfulImage } from './interfaces'

const SplashImage = ({
  image,
}: {
  image: ContentfulImage
}): React.ReactElement => (
  <AspectRatioWrapper ratio={getImageAspectRatio(image)}>
    <Image
      path={image?.file?.url}
      ext={['webp', 'png']}
      formatPath={({ path, width, ext }: formatPathProps) =>
        `${path}?w=${width}&fm=${ext}`
      }
      width={450}
      stretch
    />
  </AspectRatioWrapper>
)

export default SplashImage
