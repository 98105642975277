import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import { Button, P } from '@farewill/ui'
import { screenMax, screenMin } from '@farewill/ui/helpers/responsive'
import { BREAKPOINT, COLOR, FONT, GTR } from '@farewill/ui/tokens'

import { CALLRAIL_FORWARDING_TELEPHONE_NUMBER } from 'config'
import TelephoneNumber from 'components/TelephoneNumber'
import useProductSupportStatus from 'lib/time/useProductSupportStatus'
import { LIVE_PRODUCTS } from 'lib/products/constants'
import useWindowSize from 'lib/ui/useWindowSize'

const StyledWhiteButton = styled(Button.White)`
  ${screenMax.s`
        width: 100%;
  `}

  ${({ $secondaryCta }) =>
    $secondaryCta &&
    css`
      ${screenMin.m`
        width: 100%;
        max-width: 320px;
      `}
    `}
`

const StyledPrimaryButton = styled(Button.Primary)`
  ${screenMax.s`
        width: 100%;
  `}

  ${({ $secondaryCta }) =>
    $secondaryCta &&
    css`
      ${screenMin.m`
        width: 100%;
        max-width: 320px;
      `}
    `}
`

const StyledP = styled(P)`
  width: fit-content;
  margin: ${GTR.XS} auto 0;

  ${screenMin.m`
    margin: ${GTR.XS} 0 0;
  `}
`

const StyledTelephoneNumber = styled(TelephoneNumber)`
  font-weight: ${FONT.WEIGHT.MEDIUM};
  margin-left: ${GTR.XXS};
`

const LinkCta = ({
  text,
  link,
  primary,
  secondaryCta,
  showArrowIcon,
  product,
}) => {
  const internal = link?.includes('farewill.com/')
  const { width: windowWidth } = useWindowSize()

  return (
    <>
      {primary ? (
        <StyledPrimaryButton
          tag={internal ? Link : 'a'}
          target={internal ? '_self' : '_blank'}
          href={link}
          to={link}
          forward={showArrowIcon}
          $secondaryCta={secondaryCta}
        >
          {text}
        </StyledPrimaryButton>
      ) : (
        <StyledWhiteButton
          tag={internal ? Link : 'a'}
          target={internal ? '_self' : '_blank'}
          href={link}
          to={link}
          forward={showArrowIcon}
          $secondaryCta={secondaryCta}
        >
          {text}
        </StyledWhiteButton>
      )}
      {secondaryCta && useProductSupportStatus(product) && (
        <StyledP color={COLOR.BLACK}>
          Or call us {windowWidth > BREAKPOINT.S && 'now'} on{' '}
          <StyledTelephoneNumber
            telephoneNumber={CALLRAIL_FORWARDING_TELEPHONE_NUMBER}
            underline
            showIcon={windowWidth > BREAKPOINT.S}
          />
        </StyledP>
      )}
    </>
  )
}

LinkCta.propTypes = {
  link: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  primary: PropTypes.bool,
  secondaryCta: PropTypes.bool,
  showArrowIcon: PropTypes.bool,
  product: PropTypes.oneOf(LIVE_PRODUCTS).isRequired,
}

LinkCta.defaultProps = {
  primary: true,
  secondaryCta: false,
  showArrowIcon: true,
}

export default LinkCta
