import React from 'react'
import PropTypes from 'prop-types'

import { LIVE_PRODUCTS, WILLS } from 'lib/products/constants'

import { CTA_TYPES } from './constants'
import PhoneCta from './PhoneCta'
import LinkCta from './LinkCta'

const Cta = ({
  type,
  link,
  text,
  secondaryCta,
  showArrowIcon,
  primary,
  product,
  telephoneNumber,
  useSwappedNumber,
}) => {
  switch (type) {
    case CTA_TYPES.TELEPHONE_NUMBER:
      return (
        <PhoneCta
          product={product}
          primary={primary}
          text={text}
          secondaryCta={secondaryCta}
          telephoneNumber={telephoneNumber}
          useSwappedNumber={useSwappedNumber}
        />
      )
    case CTA_TYPES.LINK:
      return (
        <LinkCta
          text={text}
          link={link}
          product={product}
          primary={primary}
          secondaryCta={secondaryCta}
          showArrowIcon={showArrowIcon}
        />
      )
    default:
      return null
  }
}

Cta.propTypes = {
  type: PropTypes.string.isRequired,
  link: PropTypes.string,
  text: PropTypes.string.isRequired,
  secondaryCta: PropTypes.bool,
  showArrowIcon: PropTypes.bool,
  primary: PropTypes.bool,
  product: PropTypes.oneOf(LIVE_PRODUCTS),
  telephoneNumber: PropTypes.string,
  useSwappedNumber: PropTypes.bool,
}

Cta.defaultProps = {
  link: null,
  secondaryCta: false,
  showArrowIcon: true,
  primary: true,
  product: WILLS,
  telephoneNumber: '',
  useSwappedNumber: true,
}

export default Cta
