import React from 'react'
import styled from 'styled-components'
import { Image, Wrapper } from '@farewill/ui'
import { COLOR, GTR, BORDER } from '@farewill/ui/tokens'
import { screenMin } from '@farewill/ui/helpers/responsive'

import { formatPathProps } from 'types/types'
import AspectRatioWrapper from 'components/AspectRatioWrapper'

import { TeamMember } from './interfaces'
import { getImageAspectRatio } from './helpers'

const StyledPhotoWrapper = styled(Wrapper)`
  padding: 0 ${GTR.L} 0 ${GTR.XS};
  ${screenMin.l`
    padding: ${GTR.XS} ${GTR.L} ${GTR.L} ${GTR.S};
  `};
`

const StyledPhotoFrame = styled(Image)`
  border: solid ${COLOR.WHITE} 10px;
  background-color: ${COLOR.WHITE};
  margin-top: -${GTR.XL};
  box-shadow: ${BORDER.SHADOW.M};
  transform: rotate(3deg);

  ${screenMin.m`
    margin-top: -92px;
  `}

  ${screenMin.l`
    margin-top: 0;
  `};

  // Styles for IE11
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    margin-top: 0;
  }
`

const TeamMemberPhoto = ({
  teamMember,
}: {
  teamMember: TeamMember
}): React.ReactElement => (
  <StyledPhotoWrapper>
    <AspectRatioWrapper ratio={getImageAspectRatio(teamMember.image)}>
      <StyledPhotoFrame
        path={teamMember.image?.file?.url}
        ext={['webp', 'jpg']}
        formatPath={({ path, width, ext }: formatPathProps) =>
          `${path}?w=${width}&fm=${ext}`
        }
        width={450}
        stretch
        alt={`${teamMember.name}, ${teamMember.title}`}
      />
    </AspectRatioWrapper>
  </StyledPhotoWrapper>
)

export default TeamMemberPhoto
