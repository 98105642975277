import { BLOCKS } from '@contentful/rich-text-types'

export const transformArrayToObject = (data) => {
  const dataAsObject = data
    ? data.reduce((object, item) => {
        // eslint-disable-next-line no-param-reassign
        object[item.contentful_id] = item
        return object
      }, {})
    : {}
  return dataAsObject
}

export const getRichContent = (data) => {
  let parsedData = {
    nodeType: BLOCKS.DOCUMENT,
    content: [],
    data: {},
  }
  try {
    parsedData = data && JSON.parse(data)
  } catch (err) {
    console.warn(err)
  }

  return parsedData
}
