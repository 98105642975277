import styled from 'styled-components'
import { Wrapper } from '@farewill/ui'
import { GTR, COLOR, FONT, BORDER_RADIUS } from '@farewill/ui/tokens'
import { screenMin, screenMax } from '@farewill/ui/helpers/responsive'

const SectionContentWrapper = styled(Wrapper)`
  h3,
  h4 {
    color: ${COLOR.GREY.DARK};
  }

  ul {
    li {
      position: relative;
      list-style-type: none;
      margin-bottom: ${GTR.S};
      padding-left: ${GTR.M};

      ${screenMin.m`
        padding-left: ${GTR.XL};
      `}
    }

    li::before {
      position: absolute;
      left: 0;
      top: 6px;
      content: '';
      padding: ${GTR.XS} ${GTR.S} ${GTR.XS} ${GTR.XS};
      background-repeat: no-repeat;
      background-position: left center;
      background-size: ${GTR.XS};
      background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.9565 0.0800942C19.7244 -0.427904 23.5804 1.49209 26.5764 5.51207C29.5724 9.53206 30.1324 12.204 29.9764 15C29.7204 19.568 27.6004 23.264 25.8484 25.692C24.0964 28.12 21.8084 30.016 17.6964 30C13.6684 29.984 6.36447 28.556 3.29648 24.932C0.228495 21.308 -0.727502 17.032 0.552494 11.96C1.83249 6.88807 4.18848 0.588092 11.9565 0.0800942Z' fill='%23FFDF4E'/%3E%3C/svg%3E%0A");

      ${screenMin.m`
        left: ${GTR.M};
      `}
    }
  }

  figure {
    margin: 0;
  }

  img + em,
  figcaption {
    display: block;
    font-style: normal;
    font-size: ${FONT.SIZE.S};
    margin: ${GTR.S} 0 ${GTR.M};
  }

  ol {
    list-style: none;
    counter-reset: list;
    padding: 0;

    & > li {
      position: relative;
      margin-bottom: ${GTR.S};
      counter-increment: list;
      padding-left: ${GTR.XL};
    }

    & > li::before {
      font-family: ${FONT.FAMILY.DECORATIVE};
      color: ${COLOR.BLACK};
      position: absolute;
      left: 0;
      top: 0;
      content: counter(list);
      height: 30px;
      width: 30px;
      line-height: 30px;
      text-align: center;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.9565 0.0800942C19.7244 -0.427904 23.5804 1.49209 26.5764 5.51207C29.5724 9.53206 30.1324 12.204 29.9764 15C29.7204 19.568 27.6004 23.264 25.8484 25.692C24.0964 28.12 21.8084 30.016 17.6964 30C13.6684 29.984 6.36447 28.556 3.29648 24.932C0.228495 21.308 -0.727502 17.032 0.552494 11.96C1.83249 6.88807 4.18848 0.588092 11.9565 0.0800942Z' fill='%23FFDF4E'/%3E%3C/svg%3E%0A");
    }

    &.steps {
      padding-bottom: ${GTR.XXS};

      & > li {
        padding: ${GTR.XS} 0;

        ${screenMin.l`
          padding-top: 0;
          padding-bottom: 0;
          padding-left: ${GTR.XL};
        `}
      }

      & > li {
        ${screenMin.l`
          padding-bottom: ${GTR.XL};
        `}
      }

      & > li::before {
        width: 50px;
        height: 50px;
        font-size: ${FONT.SIZE.L};
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        ${screenMin.l`
          position: absolute;
        `}
      }
    }
  }

  a {
    color: ${COLOR.BLACK};
  }

  blockquote {
    border-left: 7px solid ${COLOR.ACCENT.PRIMARY};
    margin-left: 0;
    margin-right: 0;
    padding-left: calc(${GTR.XL} - ${GTR.L});

    ${screenMin.m`
        margin-left: ${GTR.M};
      `}

    p {
      font-family: ${FONT.FAMILY.DECORATIVE};
      font-size: ${FONT.SIZE.L};
    }

    b {
      display: block;
      font-family: ${FONT.FAMILY.BODY};
      font-size: ${FONT.SIZE.XS};
      text-transform: uppercase;
      font-weight: ${FONT.WEIGHT.BOLD};
      letter-spacing: 1px;
      margin-top: ${GTR.S};
    }

    blockquote {
      border: none;
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;

      p {
        font-family: ${FONT.FAMILY.BODY};
        font-size: ${FONT.SIZE.XS};
        text-transform: uppercase;
        font-weight: ${FONT.WEIGHT.BOLD};
        letter-spacing: 1px;
      }
    }
  }

  .template-howtosteps {
    ol {
      & > li {
        & > :first-child {
          font-size: ${FONT.SIZE.XL};
          font-weight: ${FONT.WEIGHT.MEDIUM};
          color: ${COLOR.BLACK};
          margin: ${GTR.M} 0;
          line-height: 1.25;

          ${screenMin.m`
            padding-left: ${GTR.L};
            padding-top: ${GTR.XXS};
            margin: ${GTR.XL} 0;
          `}
        }

        &::after {
          ${screenMin.m`
            content: '';
            position: absolute;
            top: 60px;
            bottom: -20px;
            left: 25px;
            width: 1px;
            background: ${COLOR.ACCENT.PRIMARY};
          `}
        }

        &::before {
          width: 50px;
          height: 50px;
          font-size: ${FONT.SIZE.XL};

          ${screenMax.m`
            position: relative;
            display: block;
          `}
        }

        ${screenMax.m`
          padding: ${GTR.XS} 0;
          
        `}
      }
    }
  }

  .template-callout {
    padding: ${GTR.L};
    background: ${COLOR.BACKGROUND.FOG};
    margin: ${GTR.M} 0;
    border-radius: ${BORDER_RADIUS.S};

    & > :first-child {
      margin-top: 0;
      padding-top: 0;
    }

    & > :last-child {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  .template-date {
    font-size: ${FONT.SIZE.M};
    font-weight: ${FONT.WEIGHT.BOLD};
    position: relative;
    padding-left: ${GTR.L};
    display: block;

    p {
      line-height: 1.25;
    }

    &::before {
      content: '';
      display: inline-block;
      width: 24px;
      height: 24px;
      position: absolute;
      top: 0;
      left: 0;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 21.6C17.3019 21.6 21.6 17.3019 21.6 12C21.6 6.69806 17.3019 2.4 12 2.4C6.69806 2.4 2.4 6.69806 2.4 12C2.4 17.3019 6.69806 21.6 12 21.6ZM13.2 11.6202V7.2C13.2 6.53726 12.6627 6 12 6C11.3373 6 10.8 6.53726 10.8 7.2V12C10.8 12.0315 10.8012 12.0627 10.8036 12.0936C10.7569 12.5088 10.9287 12.9365 11.2908 13.1996L14.2033 15.3157C14.7395 15.7052 15.4899 15.5864 15.8794 15.0502C16.269 14.514 16.1501 13.7636 15.614 13.374L13.2 11.6202ZM12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24Z' fill='%23495456'/%3E%3C/svg%3E");
    }
  }
`
export default SectionContentWrapper
