import React from 'react'
import PropTypes from 'prop-types'

import { ButtonGroup, Wrapper, H } from '@farewill/ui'

import Cta from 'components/Cta'
// eslint-disable-next-line import/no-cycle
import RichContent from '../RichContent'

const CalloutWrapper = ({ children }) => (
  <Wrapper
    margin={['S', 0]}
    borderRadius="S"
    background="#fff9dc"
    padding="S"
    paddingFromM="XL"
  >
    {children}
  </Wrapper>
)

const Callout = ({ title, content, cta, secondCta }) => {
  const ctaComponent = cta && (
    <Cta
      type={cta.type}
      link={cta.link}
      text={cta.text}
      secondaryCta={cta.secondaryCta}
      showArrowIcon={cta.arrowIcon}
    />
  )

  const secondCtaComponent = secondCta && (
    <Cta
      type={secondCta.type}
      link={secondCta.link}
      text={secondCta.text}
      secondaryCta={secondCta.secondaryCta}
      showArrowIcon={secondCta.arrowIcon}
    />
  )

  return (
    <CalloutWrapper>
      <H tag="h3" size="S">
        {title}
      </H>
      <RichContent data={content} />
      {(cta || secondCta) && (
        <ButtonGroup align="left" horizontalFromM leftToRight>
          {ctaComponent}
          {secondCtaComponent}
        </ButtonGroup>
      )}
    </CalloutWrapper>
  )
}

Callout.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.shape({
    raw: PropTypes.string,
  }).isRequired,
  cta: PropTypes.shape({
    type: PropTypes.string.isRequired,
    link: PropTypes.string,
    text: PropTypes.string.isRequired,
    secondaryCta: PropTypes.bool,
    arrowIcon: PropTypes.bool,
  }),
  secondCta: PropTypes.shape({
    type: PropTypes.string.isRequired,
    link: PropTypes.string,
    text: PropTypes.string.isRequired,
    secondaryCta: PropTypes.bool,
    arrowIcon: PropTypes.bool,
  }),
}

Callout.defaultProps = {
  cta: null,
  secondCta: null,
}

CalloutWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default Callout
