import React from 'react'
import styled from 'styled-components'
import { H, P, Grid, Wrapper } from '@farewill/ui'
import { COLOR, FONT } from '@farewill/ui/tokens'

import Cta from 'components/Cta'
import BackgroundSwitch from './BackgroundSwitch'
import TeamMemberPhoto from './TeamMemberPhoto'
import SplashImage from './SplashImage'
import { SplashProps } from './interfaces'

const StyledContentWrapper = styled(Grid.Item)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const StyledHeader = styled(H)`
  font-size: ${FONT.SIZE.XL};
`

const Splash = ({
  title,
  backgroundType,
  content,
  cta,
  image,
  teamMember,
  product,
}: SplashProps): React.ReactElement => (
  <BackgroundSwitch backgroundType={backgroundType}>
    <Grid
      container
      containerPaddingTop={0}
      containerPaddingBottom={0}
      gap={['XL', 'M']}
      gapFromXL="XL"
      gapFromM={teamMember ? [0, 'L'] : 'L'}
    >
      <StyledContentWrapper
        startRow={2}
        startRowFromL={1}
        startColumnFromM={2}
        startColumnFromXL={3}
        spanFromM={10}
        spanFromL={5}
        spanFromXL={5}
      >
        <StyledHeader tag="h2" decorative>
          {title}
        </StyledHeader>
        <P color={COLOR.BLACK} margin={[0, 0, 'M']}>
          {content?.content}
        </P>
        {cta && cta.type && (
          <Wrapper>
            <Cta
              type={cta.type}
              link={cta.link}
              text={cta.text}
              product={product}
              secondaryCta={cta.secondaryCta}
              showArrowIcon={cta.arrowIcon}
              primary={false}
            />
          </Wrapper>
        )}
      </StyledContentWrapper>
      <StyledContentWrapper
        startRow={1}
        startColumn={1}
        span={12}
        startColumnFromM={2}
        startColumnFromL={8}
        spanFromM={7}
        spanFromL={5}
      >
        {teamMember && <TeamMemberPhoto teamMember={teamMember} />}
        {!teamMember && image && <SplashImage image={image} />}
      </StyledContentWrapper>
    </Grid>
  </BackgroundSwitch>
)

export default Splash
