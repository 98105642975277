export const SECTION_TYPES = {
  WIDGET: 'ContentfulWidget',
  STEPS: 'ContentfulStepsSection',
  CONTENT: 'ContentfulContentSection',
  SPLASH: 'ContentfulSplashSection',
  CALLOUT: 'ContentfulCallout',
  RICH_IMAGE: 'ContentfulRichImage',
  CTA: 'ContentfulCallToAction',
  MODULE: 'ContentfulModule',
}

export enum BACKGROUND_TYPES {
  PLAIN = 'Plain background',
  TEXTURES = 'Background with textures',
  EDGE = 'Background with edge',
}
