import React from 'react'
import styled from 'styled-components'
import { screenMin } from '@farewill/ui/helpers/responsive'
import { COLOR, GTR } from '@farewill/ui/tokens'
import { Image } from '@farewill/ui'
import { formatPathProps } from 'types/types'

const StyledImage = styled(Image)`
  width: 100%;
`

const StyledCaption = styled.figcaption`
  font-size: ${GTR.S};
  margin: ${GTR.M} 0;
  padding-top: ${GTR.XS};
  border-top: 1px solid ${COLOR.BLACK};
`

const StyledRichImage = styled.figure`
  margin: 0;
  max-width: 327px;

  ${screenMin.m`
    max-width: 480px;
  `}

  ${screenMin.l`
    max-width: 562px;
  `}
`

interface RichImageProps {
  data: {
    alternativeText: string
    caption?: string
    imageDesktop: {
      file: {
        url: string
      }
    }
    imageMobile?: {
      file?: {
        url?: string
      }
    }
  }
}

const RichImage = ({
  data: { alternativeText, caption, imageDesktop, imageMobile },
}: RichImageProps): React.ReactElement => (
  <StyledRichImage>
    <StyledImage
      path={imageMobile ? imageMobile?.file?.url : imageDesktop.file.url}
      pathFromM={imageDesktop.file.url}
      ext={['webp', 'png']}
      formatPath={({ path, width, ext }: formatPathProps) =>
        `${path}?w=${width}&fit=pad&fm=${ext}`
      }
      alt={alternativeText}
      width={327}
      widthFromM={480}
      widthFromL={562}
      stretch
    />
    <StyledCaption>{caption}</StyledCaption>
  </StyledRichImage>
)

export default RichImage
