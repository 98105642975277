import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { GTR } from '@farewill/ui/tokens'
import { screenMin } from '@farewill/ui/helpers/responsive'

import WrapperWithEdge from 'components/WrapperWithEdge'

import { BACKGROUND_TYPES } from '../../constants'

const StyledWrapper = styled(WrapperWithEdge)<{ width: number }>`
  position: relative;
  left: 50%;
  margin-top: ${GTR.XXL};
  margin-bottom: ${GTR.XL};

  ${({ width }) => css`
    ${width
      ? `width: ${width}px; margin-left: -${width / 2}px;`
      : `width: 100vw; margin-left: -50vw;`}

    ${screenMin.xl`
      ${width ? `margin-left: -${width / 2 + 51}px;` : `margin-left: -50vw;`}
    `}
  `}

  ${screenMin.xl`
    margin-bottom: ${GTR.XXL};
  `}

  // Styles for IE11
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    left: 0;
    margin-left: -${GTR.XXL};
  }
`
interface BackgroundSwitchProps {
  backgroundType: BACKGROUND_TYPES
  children: React.ReactNode
}

const BackgroundSwitch = ({
  backgroundType,
  children,
}: BackgroundSwitchProps): React.ReactElement => {
  const [splashWidth, setSplashWidth] = useState(0)

  useEffect(() => {
    const setWidth = () => setSplashWidth(document?.body?.clientWidth)

    setWidth()
    window.addEventListener('resize', setWidth)
    return () => window.removeEventListener('resize', setWidth)
  }, [])

  switch (backgroundType) {
    default:
      return (
        <StyledWrapper
          lightAccent
          thickEdge
          padding={[0, 0, 'XL']}
          paddingFromL={[0, 0, 'XS']}
          direction="vertical"
          width={splashWidth}
        >
          {children}
        </StyledWrapper>
      )
  }
}

export default BackgroundSwitch
