import React from 'react'
import styled from 'styled-components'
import { H, Wrapper } from '@farewill/ui'
import { COLOR, GTR, FONT } from '@farewill/ui/tokens'
import { screenMin } from '@farewill/ui/helpers/responsive'

import MarkdownWrapper from 'components/MarkdownWrapper'
import SectionContentWrapper from './SectionContentWrapper'

// On contentful, we are only allowing the embedding of callout or rich image
// so there will never be a dependency cycle
// eslint-disable-next-line import/no-cycle
import RichContent from './RichContent'

const TimelineWrapper = styled.div`
  font-size: ${FONT.SIZE.M};
  font-weight: ${FONT.WEIGHT.BOLD};
  position: relative;
  padding-left: ${GTR.L};
  display: block;

  &::before {
    content: '';
    display: inline-block;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 21.6C17.3019 21.6 21.6 17.3019 21.6 12C21.6 6.69806 17.3019 2.4 12 2.4C6.69806 2.4 2.4 6.69806 2.4 12C2.4 17.3019 6.69806 21.6 12 21.6ZM13.2 11.6202V7.2C13.2 6.53726 12.6627 6 12 6C11.3373 6 10.8 6.53726 10.8 7.2V12C10.8 12.0315 10.8012 12.0627 10.8036 12.0936C10.7569 12.5088 10.9287 12.9365 11.2908 13.1996L14.2033 15.3157C14.7395 15.7052 15.4899 15.5864 15.8794 15.0502C16.269 14.514 16.1501 13.7636 15.614 13.374L13.2 11.6202ZM12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24Z' fill='%23495456'/%3E%3C/svg%3E");
  }
`

const StyledStepItem = styled.li`
  ul {
    padding-left: ${GTR.XS};
  }

  &::after {
    ${screenMin.l`
      content: '';
      position: absolute;
      top: 60px;
      bottom: -15px;
      left: 25px;
      width: 1px;
      background: ${COLOR.ACCENT.PRIMARY};
    `}
  }

  &:last-child {
    &::after {
      ${screenMin.l`
      bottom: 30px;
    `}
    }
  }
`

const StyledTitle = styled(H)`
  color: ${COLOR.BLACK};
  margin: ${GTR.M} 0;

  ${screenMin.l`
    padding-left: ${GTR.L};
    padding-top: ${GTR.XXS};
  `}
`

interface RichContentInt {
  raw: string
  references: []
}
interface Step {
  id: string
  title?: string
  slug: string
  timeline?: string
  content: {
    childMarkdownRemark: {
      html: string
    }
  }
  richContent: RichContentInt
}

interface SingleStepProps {
  step: Step
}

const SingleStep = ({
  step: { slug, title, content, richContent, timeline },
}: SingleStepProps): React.ReactElement => (
  <StyledStepItem>
    <StyledTitle tag="h2" size="M" id={slug}>
      {title}
    </StyledTitle>
    {timeline && <TimelineWrapper>{timeline}</TimelineWrapper>}
    {richContent?.raw ? (
      <RichContent data={richContent} />
    ) : (
      <Wrapper
        tag={MarkdownWrapper as any}
        // eslint-disable-next-line react/no-danger
        content={content.childMarkdownRemark.html}
      />
    )}
  </StyledStepItem>
)

interface StepsProps {
  data: {
    title: string
    content: {
      childMarkdownRemark: {
        html: string
      }
    }
    steps: Step[]
    richContent: RichContentInt
  }
}

const SectionStepsWrapper = ({
  data: { title, content, steps, richContent },
}: StepsProps): React.ReactElement => (
  <SectionContentWrapper>
    <H tag="h2" size="L">
      {title}
    </H>
    {richContent?.raw && <RichContent data={richContent} />}
    {!richContent?.raw && (
      <Wrapper
        tag={MarkdownWrapper as any}
        // eslint-disable-next-line react/no-danger
        content={content?.childMarkdownRemark?.html}
      />
    )}
    <ol className="steps">
      {steps.map((step) => (
        <SingleStep step={step} key={`${step.id}`} />
      ))}
    </ol>
  </SectionContentWrapper>
)

export default SectionStepsWrapper
