import React from 'react'
import PropTypes from 'prop-types'
import DefaultLayoutContext from 'layouts/context'
import { StyledRichContentWrapper } from 'views/general/templates/components/RichContent'
import { OUTPUT } from '../../../plugins/remark-replace-phone-number-plugin/plugin'

const MarkdownWrapper = ({ content, className }) => {
  const [html, setHtml] = React.useState(content)
  const { trackedPhoneNumber } = React.useContext(DefaultLayoutContext)

  React.useLayoutEffect(() => {
    if (trackedPhoneNumber) {
      setHtml(
        content
          .split(OUTPUT)
          .join(`<a href="tel:${trackedPhoneNumber}">${trackedPhoneNumber}</a>`)
      )
    }
  }, [content, trackedPhoneNumber])

  return (
    <StyledRichContentWrapper
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: html,
      }}
      className={className}
    />
  )
}

MarkdownWrapper.propTypes = {
  content: PropTypes.string.isRequired,
  className: PropTypes.string,
}

MarkdownWrapper.defaultProps = {
  className: null,
}

export default MarkdownWrapper
