/* eslint-disable no-underscore-dangle, react/display-name  */
import React, { useContext } from 'react'
import styled from 'styled-components'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import {
  BLOCKS,
  INLINES,
  Document,
  NodeData,
} from '@contentful/rich-text-types'
import { Image } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'
import { headingXS, headingS, headingM } from '@farewill/ui/helpers/text'
import PageContext from 'PageContext'
import DefaultLayoutContext from 'layouts/context'
import { CALLRAIL_FORWARDING_TELEPHONE_NUMBER } from 'config'
import formatTelephoneNumber from 'lib/formatting/telephoneNumber'
import Cta from 'components/Cta'
import { formatPathProps } from 'types/types'
import { getRichContent, transformArrayToObject } from './helpers'
import { SECTION_TYPES } from '../../constants'
import SectionContentWrapper from '../SectionContentWrapper'
// eslint-disable-next-line import/no-cycle
import Callout from '../Callout'
import Splash from '../Splash'
import Content from '../Content'
import RichImage from '../RichImage'
import SectionStepsWrapper from '../SectionStepsWrapper'

export const StyledRichContentWrapper = styled.div`
  hr {
    height: 2px;
    background: ${COLOR.GREY.LIGHT};
    border: 0;
    margin: ${GTR.L} 0;
  }
  }

  h2 {
    ${headingM}
  }

  h3 {
    ${headingS}
  }

  h4 {
    ${headingXS}
  }

  h2 {
    margin-top: ${GTR.XL};
  }

  h3,
  h4 {
    margin-top: ${GTR.L};
  }

  img {
    max-width: 100%;
  }

  .gatsby-resp-iframe-wrapper {
    margin: ${GTR.M} 0;
  }
`

interface RichContentData {
  nodeType: BLOCKS | INLINES
  content: RichContentData[]
  data: Record<string, string>
}

const swapTrackedPhoneNumber = (
  data: RichContentData,
  trackedPhoneNumber: string
): RichContentData => {
  const content = data?.content?.map((singleNode) => {
    const childContent = singleNode?.content?.map((nodeChild) => {
      if (
        nodeChild.nodeType === 'hyperlink' &&
        nodeChild.data.uri === 'phone number'
      ) {
        const linkContent = nodeChild?.content?.map((linkChild) => ({
          ...linkChild,
          value:
            trackedPhoneNumber ||
            formatTelephoneNumber(CALLRAIL_FORWARDING_TELEPHONE_NUMBER),
        }))
        return {
          ...nodeChild,
          data: {
            uri: `tel:${
              trackedPhoneNumber || CALLRAIL_FORWARDING_TELEPHONE_NUMBER
            }`,
          },
          content: linkContent,
        }
      }
      if (nodeChild.content) {
        return swapTrackedPhoneNumber(nodeChild, trackedPhoneNumber)
      }
      return nodeChild
    })

    return { ...singleNode, content: childContent }
  })

  return { ...data, content }
}

const RichContent = ({
  data,
  className,
}: {
  data:
    | GatsbyTypes.ContentfulContentSectionRichContent
    | GatsbyTypes.ContentfulLocalFuneralPageFreetextContent
  className?: string
}): React.ReactElement => {
  const { trackedPhoneNumber } = React.useContext(DefaultLayoutContext)

  const parsedRichContentBeforePhoneSwap = getRichContent(data?.raw)
  const parsedRichContent = swapTrackedPhoneNumber(
    parsedRichContentBeforePhoneSwap,
    trackedPhoneNumber
  )
  const references = 'references' in data && data.references
  const referencesObject = transformArrayToObject(references)

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: ({ data: nodeData }: { data: NodeData }) => {
        const { id } = nodeData.target.sys

        if (referencesObject[id] === undefined) return null
        return (
          <Image
            formatPath={({ path, width, ext }: formatPathProps) =>
              `${path}?w=${width}&fm=${ext}`
            }
            ext={['webp', 'jpg']}
            path={referencesObject[id].file.url}
            width={800}
          />
        )
      },
      [INLINES.EMBEDDED_ENTRY]: ({ data: nodeData }: { data: NodeData }) => {
        const { id } = nodeData.target.sys
        if (referencesObject[id] === undefined) return null

        const { moduleContent } = referencesObject[id]

        switch (referencesObject[id].__typename) {
          case SECTION_TYPES.MODULE:
            return moduleContent
          default:
            return null
        }
      },
      [BLOCKS.EMBEDDED_ENTRY]: ({ data: nodeData }: { data: NodeData }) => {
        const { id } = nodeData.target.sys
        const { product } = useContext(PageContext)
        if (referencesObject[id] === undefined) return null

        const {
          backgroundType,
          title,
          content,
          richContent,
          cta,
          image,
          teamMember,
          slug,
          __typename,
          type,
          link,
          text,
        } = referencesObject[id]

        switch (referencesObject[id].__typename) {
          case SECTION_TYPES.CALLOUT:
            return (
              <Callout
                title={title}
                content={richContent || content}
                cta={cta}
              />
            )
          case SECTION_TYPES.SPLASH:
            return (
              <Splash
                backgroundType={backgroundType}
                title={title}
                content={content}
                cta={cta}
                image={image}
                teamMember={teamMember}
                product={product}
              />
            )
          case SECTION_TYPES.RICH_IMAGE:
            return (
              <Content title={title} slug={slug} sectionType={__typename}>
                <RichImage data={referencesObject[id]} />
              </Content>
            )
          case SECTION_TYPES.STEPS:
            return (
              <Content title={title} slug={slug} sectionType={__typename}>
                <SectionStepsWrapper data={referencesObject[id]} />
              </Content>
            )

          case SECTION_TYPES.CTA:
            return <Cta type={type} link={link} text={text} />

          default:
            return null
        }
      },
    },
  }

  return (
    <SectionContentWrapper className={className}>
      <StyledRichContentWrapper>
        {documentToReactComponents(parsedRichContent as Document, options)}
      </StyledRichContentWrapper>
    </SectionContentWrapper>
  )
}

export default RichContent
